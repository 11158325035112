import "./styles/core.scss";
import "bootstrap-icons/font/bootstrap-icons.scss";
import htmx from "htmx.org";
import { useDark } from "@vueuse/core";

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions, no-unused-vars
    interface Window {
        htmx: typeof htmx
    }
}

htmx.config.historyCacheSize = 0;
htmx.config.allowEval = false;
htmx.config.includeIndicatorStyles = true;
// @ts-expect-error this is documented https://htmx.org/docs/
htmx.config.selfRequestsOnly = true;

window.htmx = htmx;

useDark({
    selector: "body",
    attribute: "data-bs-theme",
    valueDark: "dark",
    valueLight: "light",
});

document.body.addEventListener("click", (e) => {
    if (!(e.target instanceof HTMLInputElement)) {
        return;
    }
    const confirmText = e.target.getAttribute("data-confirm");
    if (typeof confirmText !== "string") {
        return;
    }
    // eslint-disable-next-line no-alert
    if (!window.confirm(confirmText || "Are you sure you want to make this change?")) {
        e.preventDefault();
        e.target.checked = !e.target.checked;
        return false;
    }
});
